export default {
    items: [
        {
            name: 'Dashboard',
            url: '/dashboard',
            icon: 'icon-speedometer',
            badge: {
                variant: 'primary'
            }
        },
        {
            name: 'Asset Management',
            url: '/asset',
            icon: 'fa fa-tasks',
            children: [
                {
                    name: 'Asset',
                    url: '/asset',
                    icon: 'fa fa-star'
                },
                {
                    name: 'Asset History Log',
                    url: '/asset-history-log',
                    icon: 'fa fa-history'
                }
            ]
        },
        {
            name: 'Transactions',
            url: '/transaction',
            icon: 'icon-book-open',
            children: [
                {
                    name: 'Inventory Session',
                    url: '/inventory',
                    icon: 'fa fa-bar-chart'
                },
                {
                    name: 'Dispatch',
                    url: '/dispatch',
                    icon: 'fa fa-upload'
                },
                {
                    name: 'Receipt',
                    url: '/receipt',
                    icon: 'fa fa-map-pin'
                }
            ]
        },
        {
            name: 'Reports',
            url: '/reports',
            icon: 'fa fa-line-chart',
            children: [
                {
                    name: 'Assets With Truckers',
                    url: '/assets-with-truckers',
                    icon: 'fa fa-truck'
                },
                {
                    name: 'Inventory Summary',
                    url: '/inventory-summary',
					icon: 'fa fa-table',
                },
            ]
        }
    ]
};
